import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
  Heading,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./brands.css";
import "./normalize.css";
import DonateImage from "./images/icons/buy-me-a-coffee.svg";
import DiscordImage from "./images/icons/discord.svg";
import PatreonImage from "./images/icons/patreon.svg";
import YoutubeImage from "./images/icons/youtube.svg";
import TiktokImage from "./images/icons/tiktok.svg";
import TelegramImage from "./images/icons/telegram.svg";
import XImage from "./images/icons/x.svg";
//import BgImage from "./images/bgBLUR.png"
import BgImage from "./images/pgNoBlur2.jpg";
import LogoImage from "./images/kcLogo3.png";
import PhoneImage from "./images/icons/generic-sms.svg";
import BlogImage from "./images/icons/generic-blog.svg";
import Snowfall from "react-snowfall";
import axios from "axios";

function Home() {
  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [user, setUser] = useState(null);
  const [loginClicked, setLoginClicked] = useState(false);

  const updateViewportHeight = () => {
    setViewportHeight(window.innerHeight);
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          "https://api.kennycentral.com/api/user/roles",
          {
            withCredentials: true, // Include cookies in the request
          }
        );

        const { user, roles } = response.data;
        setUser(user);
      } catch (error) {
        //console.error("Failed to fetch user or roles:", error);
      }
    };

    fetchUserProfile();
  }, []);
  axios.defaults.withCredentials = true;

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get("https://api.kennycentral.com/api/user");
      setUser(response.data);
    } catch (error) {
      //console.error("Session not active:", error);
      setUser(null);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  const navigateToChat = () => {
    navigate("/chat"); // Navigate function called here
  };
  const navigateToCreator = () => {
    navigate("/create"); // Navigate function called here
  };

  return (
    <>
      <Snowfall
        // Changes the snowflake color
        color="white"
        // Applied to the canvas element
        //style={{ background: '#fff' }}
        // Controls the number of snowflakes that are created (default 150)
        snowflakeCount={200}
        wind={[-0.5, 2.0]}
      />
      <Flex
        className="container"
        align="center"
        justify="center"
        style={{
          height: `${viewportHeight}px`,
          backgroundImage: `url(${BgImage})`, // Set background image here
          backgroundSize: "cover", // Cover the entire size of the component
          backgroundPosition: "center", // Center the background image
        }}
        bg={"#212121"}
      >
        
        <VStack spacing={4} className="row">
          <Image src={LogoImage} alt="Avatar" zIndex={10} className="avatar" />
          <Heading
            size="md"
            color="white"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.75)"
          >
            Interactive 3D South Park
          </Heading>{" "}
          <Stack
            direction="column"
            align="center"
            spacing={2}
            className="column"
          >
            <Link href="https://streamelements.com/kennycentral/tip" isExternal>
              <Button
                className="button button-coffee"
                leftIcon={<Image src={DonateImage} boxSize="24px" />}
                colorScheme="yellow"
                shadow="lg"
              >
                Donate
              </Button>
            </Link>
            <Link href="https://discord.gg/kennycentral" isExternal>
              <Button
                className="button button-discord"
                leftIcon={<Image src={DiscordImage} boxSize="24px" />}
                colorScheme="purple"
                shadow="lg"
              >
                Discord
              </Button>
            </Link>
            <Link href="https://patreon.com/kennycentral" isExternal>
              <Button
                className="button button-patreon"
                leftIcon={<Image src={PatreonImage} boxSize="24px" />}
                colorScheme="red"
                shadow="lg"
              >
                Patreon
              </Button>
            </Link>
            <Link href="https://www.youtube.com/@kennycentral" isExternal>
              <Button
                className="button button-yt"
                leftIcon={<Image src={YoutubeImage} boxSize="24px" />}
                colorScheme="blackAlpha"
                shadow="lg"
              >
                YouTube
              </Button>
            </Link>
            <Link href="https://www.tiktok.com/@kenny_central" isExternal>
              <Button
                className="button button-tiktok"
                leftIcon={<Image src={TiktokImage} boxSize="24px" />}
                colorScheme="blackAlpha"
                shadow="lg"
              >
                TikTok
              </Button>
            </Link>
            
             
            <Button
              className="button button-substack"
              shadow="lg"
              onClick={() => navigateToChat()}
              leftIcon={<Image src={PhoneImage} boxSize="24px" />}
              colorScheme="orange"
            >
              Chat App (Beta)
            </Button>
            
            
            <Button
              className="button button-fiverr"
              shadow="lg"
              onClick={() => navigateToCreator()}
              leftIcon={<Image src={BlogImage} boxSize="24px" />}
              colorScheme="green"
            >
              Episode Creator 
            </Button>
      
          </Stack>
        </VStack>
      </Flex>
    </>
  );
}

export default Home;
