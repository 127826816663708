// Import character images dynamically based on the character's value field
import ericImg from '../images/characters/eric.webp';
import stanImg from '../images/characters/stan.webp';
import kyleImg from '../images/characters/kyle.webp';
import kennyImg from '../images/characters/kennypic.webp';
import buttersImg from '../images/characters/butters.webp';
import mackeyImg from '../images/characters/mackey.webp';
import tweekImg from '../images/characters/tweek.webp';
import jimmyImg from '../images/characters/jimmy.webp';
import craigImg from '../images/characters/craig.webp';
import chefImg from '../images/characters/chef.webp';
import randyImg from '../images/characters/randy.webp';
import satanImg from '../images/characters/satan.webp';
import tokenImg from '../images/characters/tolkien.webp';
import towelieImg from '../images/characters/towelie.webp';
import garrisonImg from '../images/characters/garrison.webp';
import bebeImg from '../images/characters/bebe.webp';
import heidiImg from '../images/characters/heidi.webp';
import ikeImg from '../images/characters/ike.webp';
import hankeyImg from '../images/characters/hankey.webp';
import pipImg from '../images/characters/pip.webp';
import wendyImg from '../images/characters/wendy.webp';
import jesusImg from '../images/characters/jesus.webp';
import peterImg from '../images/characters/peter.webp';
import damienImg from '../images/characters/damien.webp';
import citywokguyImg from '../images/characters/citywokguy.webp';
import jimboImg from '../images/characters/jimbo.webp';
import gothhenriettaImg from '../images/characters/gothhenrietta.webp';
import gothmichaelImg from '../images/characters/gothmichael.webp';
import gothpeteImg from '../images/characters/gothpete.webp';
import gothfirkleImg from '../images/characters/gothfirkle.webp';
import evilmickeymouseImg from '../images/characters/evilmickeymouse.webp';
import mayormcdanielsImg from '../images/characters/mayormcdaniels.webp';
import pcprincipalImg from '../images/characters/pcprincipal.webp';
import clydeImg from '../images/characters/clyde.webp';
import geraldImg from '../images/characters/gerald.webp';
import officerbarbradyImg from '../images/characters/officerbarbrady.webp';
import kylescousinImg from '../images/characters/kylescousin.webp';
import japaneseguyImg from '../images/characters/japaneseguy.webp';
import starvinmarvinImg from '../images/characters/starvinmarvin.webp';
import alienImg from '../images/characters/alien.webp';
import terranceImg from '../images/characters/terrance.webp';
import phillipImg from '../images/characters/phillip.webp';
import nedImg from '../images/characters/ned.webp';
import buttersdadImg from '../images/characters/buttersdad.webp';
import priestmaxiImg from '../images/characters/priestmaxi.webp';
import homerImg from '../images/characters/homer.webp';
import petergriffinImg from '../images/characters/peter.webp';
import benderImg from '../images/characters/bender.webp';
import rickImg from '../images/characters/rick.webp';
import mortyImg from '../images/characters/morty.webp';
import hankImg from '../images/characters/hank.webp';
import bobbyImg from '../images/characters/bobby.webp';
import cjImg from '../images/characters/cj.webp';
import ericsmomImg from '../images/characters/liane.webp';
import narratorImg from '../images/characters/narrator.webp';
import trumpImg from '../images/characters/donaldtrump.webp';
import kamalaImg from '../images/characters/kamalaharris.webp';
import elonImg from '../images/characters/elon.webp';

const characterOptions = [
  { label: "Eric", value: "Eric", image: ericImg },
  { label: "Stan", value: "Stan", image: stanImg },
  { label: "Kenny", value: "Kenny", image: kennyImg },
  { label: "Kyle", value: "Kyle", image: kyleImg },
  { label: "Butters", value: "Butters", image: buttersImg },
  { label: "Tweek", value: "Tweek", image: tweekImg },
  { label: "Jimmy", value: "Jimmy", image: jimmyImg },
  { label: "Craig", value: "Craig", image: craigImg },
  { label: "Chef", value: "Chef", image: chefImg },
  { label: "Randy", value: "Randy", image: randyImg },
  { label: "Satan", value: "Satan", image: satanImg },
  { label: "Tolkien", value: "Token", image: tokenImg },
  { label: "Towelie", value: "Towelie", image: towelieImg },
  { label: "Mr. Garrison", value: "Garrison", image: garrisonImg },
  { label: "Mr. Mackey", value: "Makcey", image: mackeyImg },
  { label: "Bebe", value: "Bebe", image: bebeImg },
  { label: "Heidi", value: "Heidi", image: heidiImg },
  { label: "Ike", value: "Ike", image: ikeImg },
  { label: "Mr. Hankey", value: "Hankey", image: hankeyImg },
  { label: "Pip", value: "Pip", image: pipImg },
  { label: "Wendy", value: "Wendy", image: wendyImg },
  { label: "Jesus", value: "Jesus", image: jesusImg },
  { label: "Damien", value: "Damien", image: damienImg },
  { label: "City Wok Guy", value: "Citywokguy", image: citywokguyImg },
  { label: "Jimbo", value: "Jimbo", image: jimboImg },
  { label: "Goth Henrietta", value: "Gothhenrietta", image: gothhenriettaImg },
  { label: "Goth Michael", value: "Gothmichael", image: gothmichaelImg },
  { label: "Goth Pete", value: "Gothpete", image: gothpeteImg },
  { label: "Goth Firkle", value: "Gothfirkle", image: gothfirkleImg },
  { label: "Evil Mickey Mouse", value: "Evilmickeymouse", image: evilmickeymouseImg },
  { label: "Mayor McDaniels", value: "Mayormcdaniels", image: mayormcdanielsImg },
  { label: "Pc Principal", value: "Pcprincipal", image: pcprincipalImg },
  { label: "Clyde", value: "Clyde", image: clydeImg },
  { label: "Gerald", value: "Gerald", image: geraldImg },
  { label: "Officer Barbrady", value: "Officerbarbrady", image: officerbarbradyImg },
  { label: "Kyle's Cousin", value: "Kylescousin", image: kylescousinImg },
  { label: "Japanese Guy", value: "Japaneseguy", image: japaneseguyImg },
  { label: "Starvin Marvin", value: "Starvinmarvin", image: starvinmarvinImg },
  { label: "Alien", value: "Alien", image: alienImg },
  { label: "Terrance", value: "Terrance", image: terranceImg },
  { label: "Phillip", value: "Phillip", image: phillipImg },
  { label: "Ned", value: "Ned", image: nedImg },
  { label: "Mr. Stotch", value: "Buttersdad", image: buttersdadImg },
  { label: "Liane", value: "Ericsmom", image: ericsmomImg },
  { label: "Father Maxi", value: "Priestmaxi", image: priestmaxiImg },
  { label: "Peter Griffin", value: "Peter", image: petergriffinImg },
  { label: "CJ", value: "Cj", image: cjImg },
  { label: "Homer Simpson", value: "Homer", image: homerImg },
  { label: "Bender", value: "Bender", image: benderImg },
  { label: "Rick", value: "Rick", image: rickImg },
  { label: "Morty", value: "Morty", image: mortyImg },
  { label: "Hank Hill", value: "Hank", image: hankImg },
  { label: "Bobby Hill", value: "Bobby", image: bobbyImg },
  { label: "Narrator", value: "Narrator", image: narratorImg },
  { label: "Donald Trump", value: "Donaldtrump", image: trumpImg },
  { label: "Elon Musk", value: "Elonmusk", image: elonImg },
];

export default characterOptions;
